<template>
  <div class="agricultural_production fl_c">
    <div class="title_common">
		<img src="@/assets/img/header/indicate.png"/>
		<span>产业盈利概况</span>
	</div>
    <div class="fl fl_r content_box">
		<span class="lt_icon"></span>
		<span class="rt_icon"></span>
		<div class="pecent_data_box">
			<div class="pecent_data_item" v-for="(item,index) in pieData" :key="index">
				<div class="title">{{item.name}}</div>
				<div class="pecent" :style="{'color': `${item.color}`}">{{((item.value / totalCount) * 100).toFixed(2) + " %"}}</div>
			</div>
		</div>
		<div ref="agriculturalBar" class="ab_style fl_r fl5 al_c ju_c"></div>
    </div>
  </div>
</template>

<script>
import { getCulturalTourismStatistics,getTalentsStatistics } from "@/utils/api/index";

export default {
	name: "Farmingcount",
	props: {},
	
	data() {
		return {
			changePieInterval: null,
			right_bar: null,
			currentIndex: -1,
			talentsEducationStatistics: [],
			totalCount: 0,
			pieData: [
				{ value: 6088.4, name: "生产型农场",color:'#FFA500' },
				{ value: 9148.2, name: "观光型农场",color:'#0064FB' },
			],
			option: {
				color: ["#FFA500","#0064FB"],
				title : {
					show:true,//显示策略，默认值true,可选为：true（显示） | false（隐藏）
					text: '盈利占比',//主标题文本，'\n'指定换行
					x: "24%",
					y: "45%",
					textAlign: 'center',
					textStyle: {
						fontSize: 14,
						fontWeight: '500',
						color: '#fff',
					},
				},
				tooltip: {
					trigger: 'item',
					formatter: "{b}占比 : {d}%"   //鼠标放上去 展示内容
				},
				legend: {
					textStyle: {
					  color: "#E6F7FF",
					},
					top: '5%',
					right: '10%'
				},
				series: [
					{
					   name: "阴影圈",
					   type: "pie",
					   radius: ["0", "70%"],
					   center: ["25%", "50%"],
					   emphasis: {
						  scale: false,
					   },
					   tooltip: {
						  show: false,
					   },
					   itemStyle: {
						  color: "rgba(204,225,255, 0.05)",
					   },
					   zlevel: 0,
					   labelLine: {
						  show: false,
					   },
					   data: [100],
					  },
					{
						name: "额度占比",
						type: "pie",
						radius: ["45%", "57%"], // 通过设置内径与外径将饼图变为圆环饼图
						center: ['25%', '50%'],  // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
						itemStyle: {
							borderRadius: 0, // 设置圆环的圆角弧度
						},
						zlevel: 1,
						emphasis: {
							// 设置高亮时显示标签
							label: {
								show: false,
							},
							labelLine: {
								show: false,
								lineStyle: {
									color: "#fff" // 修改引导线的颜色
								}
							},
							scale: true, // 设置高亮时放大图形
							scaleSize: 7,
						},
						label: {
							color: "#fff",
							// 设置图形标签样式
							show: false, // 未高亮时不显示标签，否则高亮时显示的标签会与静态的标签重叠
							//position: "center",
							// 设置标签展示内容,其中{d}、{b}为echarts标签内容格式器
							formatter(param) {
								// correct the percentage
								return param.name + "\n" + ' (' + param.percent + '%)';
							},
						},
						data: [
							{ value: 6088.4, name: "生产型" },
							{ value: 9148.2, name: "观光型" },
						],
					},
					{
					   name: "阴影圈",
					   type: "pie",
					   radius: ["0", "35%"],
					   center: ["25%", "50%"],
					   emphasis: {
						  scale: false,
					   },
					   tooltip: {
						  show: false,
					   },
					   itemStyle: {
						  color: "rgba(204,225,255, 0.05)",
					   },
					   zlevel: 0,
					   labelLine: {
						  show: false,
					   },
					   data: [100],
					  },
				],
			},
		};
	},
	methods: {
		salesPresentation() {
			this.center_pie = this.$echarts.init(this.$refs.agriculturalBar);
			this.center_pie.setOption(this.option);
		},
		countPieData() {
			let count = 0;
			// 统计
			this.pieData.forEach(item => {
			    count = count + item.value;
			})
			this.totalCount = count;
		}
	},
	created() {
		this.countPieData()
		this.$nextTick(() => {
			this.salesPresentation();
		})
	},
};
</script>

<style scoped lang="scss">
.agricultural_production {
  width: 100%;
  height: 100%;
}
.title_common {
  width: 100%;
  height: 42px;
  line-height: 42px;
  display: flex;
  align-items: center;
  padding: 9px 0;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url(../../assets/img/header/title_03.png);
  letter-spacing: 1px;
  img {
  	width: 24px;
  	height: 24px;
  	margin-right: 16px;
  	margin-top: 2px;
  }
}

.content_box {
	background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
	box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
	margin-top: 12px;
	position: relative;
}

.item-contain {
  width: 95%;
  height: 260px;
  margin: 10px 20px;
}
.ab_style {
  width: 100%;
  height: 200px;
  //flex: 0.8;
}
.fb_style {
  width: 100px;
  height: 250px;
}
.fl5 {
  flex: 2;
}
.zindex1 {
  z-index: 1;
  color: #ffffff;
}
.img {
  width: 40px;
  // height: 40px;
}
.countfont {
  color: rgb(0, 141, 236);
  font-size: 14px;
}
.countnum {
  font-size: 22px;
}
.lt_icon {
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/header/bevel_01.png);
  width: 10px;
  height: 10px;
}
.rt_icon::after {
  content:'';
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/header/bevel_01.png);
  width: 10px;
  height: 10px;
  transform: scaleX(-1);
  position: absolute;
  right: 0;
  top: 0;
}
.pecent_data_box {
	color: #fff;
	position: absolute;
	font-size: 14px;
	right: 29%;
	top: 33.33%;
	.pecent_data_item {
		margin-bottom: 13px;
		.title {
			margin-bottom: 4px;
			letter-spacing: 1px;
		}
		.pecent {
			font-size: 18px;
		}
	}
}
</style>
