<template>
  <div class="farming_distribution fl_c">
    <div class="title_common">
		<img src="@/assets/img/header/indicate.png"/>
		<span>产业介绍</span>
    </div>
    <div class="zindex1">
		<div class="park">
			<div class="sub_title">{{ $store.state.farming.item.name }}</div>
			<div class="introduct_box">
				<span class="lt_icon"></span>
				<span class="rt_icon"></span>
				<img src="@/assets/img/bg_sl01.png"/>
				<vue-seamless-scroll
					:data="listData"
					:class-option="classOption"
					class="warp"
					ref="vueSeamlessScroll"
				>
					<div style="height: 100%;margin: 10px 0;">
						{{ detailInfo }}
					</div>
				</vue-seamless-scroll>
			</div>
			<div class="flex_box">
				<div class="small-box" v-for="(item,index) in monitorDataList" :key="index">
					<div class="fl fl_r ju_b al_c" v-for="(child,childIndex) in item" :key="childIndex">
						<div class="small-box-lt-title">{{child.key}}</div>
						<div>{{child.value}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="famring_video">
			<div class="sub_title">实时农场</div>
			<div class="video_box">
				<span class="lt_icon"></span>
				<span class="rt_icon"></span>
				<div class="videoBox"  id="playWind" style="z-index: 9999999;"></div>
				<!-- <Video
				  v-show="true"
				  :videoSrc="videoSrc"
				  :videoPoster="videoPoster"
				  :width="300"
				  :height="120"
				  :autoplay="true"
				  :controls="true"
				  :loop="false"
				  :muted="false"
				  preload="auto"
				  :showPlay="true"
				  :playWidth="96"
				  zoom="contain"
				/> -->
			</div>
		</div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import EZUIKit from 'ezuikit-js';
import { getSensorLatestData } from '@/utils/api/sensor.js'
export default {
	name: "Farmingintroduce",
	props: {
		cropData: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			monitorDataList: [
				[
					{ key: '气压', value: '' },
					{ key: '光照', value: '' },
					{ key: '气温', value: '' },
					{ key: '风向', value: '' },
					{ key: '风速', value: '' },
				],
				[
					{ key: '氮', value: '' },
					{ key: '磷', value: '' },
					{ key: '钾', value: '' },
					{ key: 'PH值', value: '' },
				],
				[
					{ key: '电导率', value: '' },
					{ key: '土壤温度', value: '' },
					{ key: '土壤湿度', value: '' },
					{ key: '空气湿度', value: '' },
					{ key: '5分钟时段雨量', value: '' },
				],
			],
			listData: [1,2],
			// 视频链接支持https或require本地地址
			videoSrc: `https://open.ys7.com/v3/openlive/F88796072_1_1.m3u8?expire=1708054401&id=548104944054435840&t=fb35034b65251ac0b06067ce1bcdf57f0c09703d4cd2b4332d2c930c7e115877&ev=100`,
			// 视频封面支持https或require本地地址
			videoPoster: `https://open.ys7.com/v3/openlive/F88796072_1_1.m3u8?expire=1708054401&id=548104944054435840&t=fb35034b65251ac0b06067ce1bcdf57f0c09703d4cd2b4332d2c930c7e115877&ev=100`,
			player: null,
			detailInfo: this.$store.state.farming.item.details
		};
	},
	computed: {
		farmingMonitorVideo() {
			return this.$store.state.farming.item.name
		},
		introductData() {
			return this.$store.state.farming.item.details
		},
		classOption() {
			return {
				 step: 0.2, // 数值越大速度滚动越快 
				 //自己是定时器轮询后端接口，由于返回的数据是1s一更新，要小于后端更新频率，不然最下面的数据还没更新
				limitMoveNum: 2, // 开始无缝滚动的数据量 this.datalist.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 0, // 单步运动停止的时间(默认值1000ms)
			}
		},
	},
	watch:{
		introductData (newVal, oldVal) {
			if(newVal) {
				this.detailInfo = newVal;
				this.$nextTick(() => {
					this.$refs.vueSeamlessScroll.reset()
				})
			}
		},
		farmingMonitorVideo (newVal, oldVal) {
			if(newVal == '梦之春果园') {
				console.log(newVal,'----监听农业板块坐标点数据')
				this.getJiangShanVideo(this.$store.state.farming.item.monitor_video)
			}
		}
	},
	beforeDestroy(){
	    if(this.player)this.player.stop();
	},
	methods: {
		// 龙盛监控接口
		getVideo() {
			let url='https://open.ys7.com/api/lapp/token/get'
			fetch(url, {
			    method: 'post',
			    headers:{
			        "Content-Type": "application/x-www-form-urlencoded"
			    },
			    body:"appKey=ebcd8f852ccb4ea59898de48e17d4778&appSecret=bff65478474c51f15bd968af6882bb45",
			    mode: 'cors'
			}).then(response => response.json()).then(res=>{
				if(this.player != null) {
					this.removeVideo()
				}
				this.player = new EZUIKit.EZUIKitPlayer({
					id: 'playWind', // 视频容器ID
					accessToken: res.data.accessToken,
					url: "ezopen://open.ys7.com/J65716566/1.hd.live",
					audio: false,
					width: 370,
					height: 204,
					template: "mobileLive",
				})
				// 初始化时，默认静音
				this.player.Theme.themeData.footer.btnList[2].defaultActive = 0;
			})
		},
		// 江山监控接口
		getJiangShanVideo(monitorVideo) {
			let url='https://open.ys7.com/api/lapp/token/get'
			fetch(url, {
				method: 'post',
				headers:{
					"Content-Type": "application/x-www-form-urlencoded"
				},
				body:"appKey=ebcd8f852ccb4ea59898de48e17d4778&appSecret=bff65478474c51f15bd968af6882bb45",
				mode: 'cors'
			}).then(response => response.json()).then(res=>{
				if(this.player != null) {
					this.removeVideo()
				}
				this.player = new EZUIKit.EZUIKitPlayer({
					id: 'playWind', // 视频容器ID
					accessToken: res.data.accessToken,
					url: monitorVideo,
					audio: true,
					width: 370,
					height: 204,
					template: "mobileLive",
				})
				this.$nextTick(() => {
					this.player.closeSound();
				})
			})
		},
		// 销毁原来的视频
		removeVideo() {
			console.log(this.player,'-----销毁视频')
			var stopPromise = this.player.stop();
			stopPromise.then((data) => {
				this.player = null;
			});
		},
		// 带id获取土壤监测信息
		async getSensorSoilData(sensorId) {
			let res = await getSensorLatestData({
				sensorType: 'soil',
				sensorId: sensorId
			})
			console.log(res,'-----土壤')
		
			this.getObjectValue(res)
		},
		// 带id获取天气监测信息
		async getSensorWeathertData(sensorId) {
			let res = await getSensorLatestData({
				sensorType: 'weather',
				sensorId: sensorId
			})
			console.log(res,'-----天气')
		
			this.getObjectValue(res)
		},
		// 处理键值对
		getObjectValue(obj) {
			let arr = Object.keys(obj);
			
			arr.forEach( (item,index) => {
				this.monitorDataList.forEach( (child,childIndex) => {
					child.forEach( (sec,secIndex) => {
						if(sec.key == item) {
							if(sec.key == '风向') {
								switch(obj[item]) {
									case 0: 
										sec.value = '北风';
										break;
									case 1:
										sec.value = '东北风';
										break;
									case 2:
										sec.value = '东风';
										break;
									case 3:
										sec.value = '东南风';
										break;
									case 4:
										sec.value = '南风';
										break;
									case 5:
										sec.value = '西南风';
										break;
									case 6:
										sec.value = '西风';
										break;
									case 7:
										sec.value = '西北风';
										break;
								}
							} else {
								sec.value = obj[item];
							}	
						}
					})
				})
			})
		},
	},
	mounted() {
		this.getVideo();
		this.getSensorWeathertData('12345677')
		this.getSensorSoilData('12345677')
	},
	components: {
		vueSeamlessScroll,
	},
};
</script>

<style scoped lang="scss">
.warp {
  width: 350px;
  height: 120px;
  //margin: 20px auto 0;
  //padding-top: 15px;
  overflow: hidden;
  //margin: 15px auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 20px;
  bottom: 20px;
  letter-spacing: 1px;
}
.farming_distribution {
  width: 100%;
  height: 100%;
}

.title_common {
  width: 100%;
  height: 42px;
  line-height: 42px;
  display: flex;
  align-items: center;
  padding: 9px 0;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url(../../assets/img/header/title_03.png);
  letter-spacing: 1px;
  img {
  	width: 24px;
  	height: 24px;
  	margin-right: 16px;
  	margin-top: 2px;
  }
}
.park {
	margin-bottom: 8px;
}
.sub_title {
	font-size: 16px;
	color: #FFFFFF;
	font-weight: 500;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	font-family: PingFang SC-Bold, PingFang SC;
	background-image: url(../../assets/img/header/pop_02.png);
	width: 100%;
	height: 42px;
	line-height: 33.33px;
	padding-left: 23px;
	margin-bottom: 8px;
	
}
.zindex1 {
  z-index: 1;
  color: #ffffff;
  margin-top: 12px;
}
.f1 {
  flex: 60;
}
.f2 {
  flex: 200;
}
.icon {
  width: 40px;
  height: 40px;
  // flex: 1;
}
.font14 {
  font-size: 14px;
}
.font26 {
  font-size: 26px;
  color: rgb(0, 141, 236);
}
.introduct_box {
  background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
  box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
  font-family: PingFang SC-Bold, PingFang SC;
  height: 154px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  img {
	  width: 100%;
	  height: 136px;
	  position: relative;
  }
}
.bgimg2 {
  background-repeat: no-repeat;
  background-size: 90% 90%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url(../../assets/img/farm2.png);
  background-position: center;
}
.flex_box {
	display: flex;
	align-items: center;
	height: 150px;
	margin-top: 8px;
}
.small-box {
  flex: 1;
  background-image: linear-gradient(0deg, rgba(21, 47, 103, 0) 0%, #152F67 100%);
  border-top: 5px solid #0961dd;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
  margin-right: 10px;
}
.small-box:last-child {
	margin-right: 0;
}
.small-box-lt-title {
	color: #7589B1;
}
.video_box {
	background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
	box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
	width: 100%;
	height: 232px;
	position: relative;
	padding: 14.5px;
	video {
		width: 100%;
		height: 100%;
	}
}
.lt_icon {
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/header/bevel_01.png);
  width: 10px;
  height: 10px;
}
.rt_icon::after {
  content:'';
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/header/bevel_01.png);
  width: 10px;
  height: 10px;
  transform: scaleX(-1);
  position: absolute;
  right: 0;
  top: 0;
}

</style>
